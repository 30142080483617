import useSWR from "swr";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import Link from "next/link";
import { getCategoryLinkAttributes } from "grandus-lib/hooks/useFilter";
import useStack from "utils/useStack";

import { useEffect } from "react";

import styles from "components/menu/Menu.module.scss";
import {
  LeftOutlined,
  CloseCircleOutlined,
  HeartOutlined,
  RightOutlined,
  UserOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import router from "next/router";

import dynamic from "next/dynamic";
const Image = dynamic(() =>
  import("grandus-lib/components-atomic/image/Image")
);

import { CATEGORY_HIGHLIGHTED_HASH } from "constants/AppConstants";

const LevelIcon = () => {
  return <span className={styles?.icon}>+</span>;
};

const LinkMobile = ({ category, onClickMethod, icon }) => {
  const hasSubmenu = get(category, "children", []).length > 0;
  return (
    <Link
      {...getCategoryLinkAttributes(
        category?.urlName,
        "",
        {},
        { absoluteHref: get(category, "externalUrl") }
      )}
      scroll={true}
    >
      <a
        className={`${styles.mobile} ${hasSubmenu ? styles?.hasSubmenu : ""}`}
        onClick={(e) => {
          if (hasSubmenu) {
            e.preventDefault();
            onClickMethod(category);
          }
        }}
      >
        {category?.name}
        {hasSubmenu ? icon : null}
      </a>
    </Link>
  );
};

const MenuItem = ({ category, handleCategoryClick, level }) => {
  return (
    <li
      className={`
        ${category?.hash === CATEGORY_HIGHLIGHTED_HASH ? styles?.special : ""}
      `}
    >
      <LinkMobile
        category={category}
        onClickMethod={() => handleCategoryClick(category)}
        icon={<LevelIcon />}
      />
    </li>
  );
};

const Submenu = ({
  parent,
  handleCategoryClick,
  handleBackClick,
  level = 0,
}) => {
  if (isEmpty(parent?.children)) {
    return null;
  }

  return (
    <ul className={`${styles?.submenu}`}>
      <li className={styles?.header}>
        <a href="#" onClick={handleBackClick}>
          <LeftOutlined className={styles?.icon} />
          {parent?.name}
        </a>
      </li>
      {map(parent?.children, (category) => (
        <MenuItem
          level={level}
          category={category}
          handleCategoryClick={handleCategoryClick}
          key={`category-${category?.id}`}
        />
      ))}
    </ul>
  );
};

const Menu = ({ isOpen = false, updateOpenedMenu, options = {} }) => {
  const {
    data: menuStack,
    pop: popStack,
    push: pushStack,
    peek: peekStack,
    isEmpty: isEmptyStack,
    truncate: truncateStack,
    length: lengthStack,
  } = useStack();

  const { data: categories, error } = useSWR(
    "/api/lib/v1/categories",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  // make page body fixed (do not scoll)
  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (isOpen) {
      body.classList.add("no-scroll");
    } else {
      body.classList.remove("no-scroll");
      truncateStack();
    }

    return () => {
      body.classList.remove("no-scroll");
      truncateStack();
    };
  }, [isOpen]);

  // close menu on route change
  useEffect(() => {
    const handleRouteChange = () => {
      updateOpenedMenu(false);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router, updateOpenedMenu]);

  const onClickToggleOpenCategory = (category) => {
    pushStack(category);
  };

  const onBackClick = (e) => {
    e.preventDefault();
    popStack();
  };

  if (isEmpty(categories)) {
    return null;
  }

  return (
    <>
      <div className={`${styles?.megamenu} ${isOpen ? styles.open : ""}`}>
        <button
          className={styles?.closeButton}
          onClick={() => {
            updateOpenedMenu(false);
            return false;
          }}
        >
          <PlusOutlined />
        </button>
        <nav>
          {!categories ? (
            <ul className={styles.main} style={{ height: "50px" }}>
              <li>&nbsp;</li>
            </ul>
          ) : (
            <>
              <Link href="/" as={`/`}>
                <a className={`${styles.logo} ${styles.mobile}`}>
                  {options?.logo ? (
                    <Image
                      photo={options.logo}
                      size={
                        options.logo?.id +
                        (options.logo?.resolution
                          ? options.logo.resolution
                          : "/180x44")
                      }
                      type={"png"}
                    />
                  ) : (
                    ""
                  )}
                </a>
              </Link>
              <ul className={styles.main}>
                {get(options, "disables.login") ? (
                  ""
                ) : (
                  <li className={styles.mobile}>
                    <Link href="/prihlasenie" as={`/prihlasenie`}>
                      <a>
                        <UserOutlined className={styles?.icon} /> Prihlásenie
                      </a>
                    </Link>
                  </li>
                )}
                {get(options, "disables.compare") ? (
                  ""
                ) : (
                  <li className={styles.mobile}>
                    <Link href="/porovnanie" as={`/porovnanie`}>
                      <a>Porovnanie</a>
                    </Link>
                  </li>
                )}

                {get(options, "disables.wishlist") ? (
                  ""
                ) : (
                  <li className={styles.mobile}>
                    <Link href="/wishlist" as={`/wishlist`}>
                      <a>
                        <HeartOutlined className={styles?.icon} /> Zoznam
                        obľúbených produktov
                      </a>
                    </Link>
                  </li>
                )}

                {categories.map((category) => {
                  return (
                    <li key={`category-${category?.id}`}>
                      <Link
                        {...getCategoryLinkAttributes(
                          category?.urlName,
                          "",
                          {},
                          { absoluteHref: get(category, "externalUrl") }
                        )}
                        scroll={true}
                      >
                        <a>{get(category, "name")}</a>
                      </Link>
                      <LinkMobile
                        category={category}
                        icon={<LevelIcon />}
                        onClickMethod={onClickToggleOpenCategory}
                      />
                    </li>
                  );
                })}
              </ul>
            </>
          )}
          {!isEmptyStack() ? (
            <Submenu
              parent={peekStack()}
              handleCategoryClick={onClickToggleOpenCategory}
              handleBackClick={onBackClick}
              level={lengthStack}
            />
          ) : null}
        </nav>
      </div>
    </>
  );
};

export default Menu;
