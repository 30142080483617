import useUser from "grandus-lib/hooks/useUser";

import { UserOutlined } from "@ant-design/icons";
import { get } from "lodash";
import Link from "next/link";

import styles from "components/user/AuthButton.module.scss";

const AuthButton = () => {
  const { user } = useUser();

  if (user) {
    return (
      <Link href="/ucet/profil" as={`/ucet/profil`}>
        <span className={styles.userButton}>
          <UserOutlined className={styles.icon} />
          <span className={styles.text}>
            {get(user, "attributes.name", get(user, "email"))}
          </span>
        </span>
      </Link>
    );
  }

  return (
    <Link href="/prihlasenie" as={`/prihlasenie`}>
      <span className={styles.userButton}>
        <UserOutlined className={styles.icon} />
        <span className={styles.text}>prihlásenie / registrácia</span>
      </span>
    </Link>
  );
};

export default AuthButton;
