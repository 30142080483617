import { useCallback } from "react";

import isFunction from "lodash/isFunction";

import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import AddForm from "components/forms/shoppingList/AddForm";

import styles from "components/shoppingList/AddNewList.module.scss";

const AddNewLists = ({
  title = "Nový zoznam",
  onBackClick,
  onCloseClick,
  onCreateCallback,
}) => {
  const handleCreate = useCallback((list) => {
    if (list?.id) {
      if (isFunction(onCreateCallback)) {
        onCreateCallback(list);
      } else {
        onCloseClick();
      }
    }
  });

  return (
    <div className={styles?.newList}>
      <div className={styles?.header}>
        {isFunction(onBackClick) ? (
          <LeftOutlined onClick={onBackClick} />
        ) : null}
        {title ? <span>{title}</span> : null}
        {isFunction(onCloseClick) ? (
          <PlusOutlined className="close-icon" onClick={onCloseClick} />
        ) : null}
      </div>
      <AddForm onCreateCallback={handleCreate} />
    </div>
  );
};

export default AddNewLists;
