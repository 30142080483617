import { Breadcrumb } from "antd";
import styles from "./Breadcrumbs.module.scss";
import Link from "next/link";
import { HomeOutlined } from "@ant-design/icons";
import { getCategoryLinkAttributes } from "grandus-lib/hooks/useFilter";
import { get, isEmpty, map } from "lodash";

const Breadcrumbs = ({ current = "", data, options = {} }) => {
  return (
    <div className={get(options, "noWrapper") ? "" : styles.wrapper}>
      <Breadcrumb>
        {get(options, "hideHomeItem") ? (
          ""
        ) : (
          <Breadcrumb.Item>
            <a href="/">
              <HomeOutlined />
            </a>
          </Breadcrumb.Item>
        )}

        {map(get(data, "categorical", []), (entry) => {
          return (
            <Breadcrumb.Item
              key={`breadcrumbs-categorical-${get(entry, "id")}`}
            >
              <a href={`/kategoria/${get(entry, "urlName")}`}>
                {get(entry, "name")}
              </a>
            </Breadcrumb.Item>
          );
        })}

        {map(get(data, "childrens", []), (entry, index) => {
          return (
            <Breadcrumb.Item
              key={`breadcrumbs-childrens-${index}`}
            >
              <Link
                href={get(entry, 'href')}
                scroll={true}
              >
                <a>{get(entry, "name")}</a>
              </Link>
            </Breadcrumb.Item>
          );
        })}

        {current ? <Breadcrumb.Item>{current}</Breadcrumb.Item> : ""}
      </Breadcrumb>
    </div>
  );
};

export default Breadcrumbs;
