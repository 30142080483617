import styles from "./ProductRow.module.scss";
import Link from "next/link";
import Image from "grandus-lib/components-atomic/image/Image";
import Price from "grandus-lib/components-atomic/price/Price";
import { truncate } from "lodash";
import AddToCartButton from "components/cart/AddToCartButton";

const RowProduct = ({
  id,
  name,
  brand,
  urlTitle,
  photo,
  finalPriceData,
  store,
}) => (
  <div className={styles?.product}>
    <a href={`/produkt/${urlTitle}`}>
      <Image photo={photo} size={"70x70"} type={"jpg"} />
    </a>

    <div className={styles?.right}>
      <a href={`/produkt/${urlTitle}`}>
        <span className={styles.name}>
          {truncate(name, {
            length: 50,
            omission: " ...",
          })}
        </span>
      </a>
      <div className={styles?.addToCart}>
        <Price
          priceData={finalPriceData}
          className={styles.price}
          options={{ hideVatPrice: true }}
        />
        <AddToCartButton productId={id} stores={store} options={{ showText: false }} />
      </div>
    </div>
  </div>
);

export default RowProduct;
