import { useCallback, useState } from "react";

import ListWithCheckboxes from "components/shoppingList/ListWithCheckboxes";
import AddNewList from "components/shoppingList/AddNewList";
import Loader from "components/_others/loader/Loader";

import styles from "components/shoppingList/addButton/Dropdown.module.scss";

const MODE_SELECT = 1;
const MODE_CREATE = 2;

const Dropdown = ({
  visible,
  isLoading,
  activeLists,
  blacklistedListsIds = [],
  onListItemClick,
  onCloseClick,
  productId,
  position = "right",
}) => {
  const [mode, setMode] = useState(MODE_SELECT);

  const toggleMode = (e) => {
    if (mode === MODE_SELECT) {
      setMode(MODE_CREATE);
    } else {
      setMode(MODE_SELECT);
    }
  };

  const dropdownPositionClassName = styles[`${position}Position`] || "";

  return (
    <div
      className={`${styles?.dropdown} ${dropdownPositionClassName} ${
        !visible && styles?.hidden
      }`}
    >
      {mode === MODE_SELECT ? (
        <ListWithCheckboxes
          activeLists={activeLists}
          blacklistedListsIds={blacklistedListsIds}
          onAddClick={toggleMode}
          onCloseClick={onCloseClick}
          onListItemClick={onListItemClick}
          productId={productId}
        />
      ) : (
        <AddNewList
          onBackClick={toggleMode}
          onCloseClick={onCloseClick}
          onCreateCallback={toggleMode}
        />
      )}
      <Loader isLoading={isLoading} />
    </div>
  );
};

export default Dropdown;
