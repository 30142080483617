export const UTC_OFFSET_MINUTES = 120; // summer time
// export const UTC_OFFSET_MINUTES = 60; // winter time
export const APP_TIMEZONE = "Europe/Bratislava";

export const PRODUCTS_CATEGORY_HASH = "FRESHBOX_PRODUCTS_CATEGORY";
export const CATEGORY_HIGHLIGHTED_HASH = "FRESHBOX_HIGHLIGHTED_CATEGORY";
export const CATEGORY_ALPHABETICAL_LISTING_HASH =
  "FRESHBOX_ALPHABETICAL_LISTING";

export const SEARCH_FILTER_PARAM_HASH = "FRESHBOX_SEARCH_FILTER_PARAM";

export const SHOPPING_LIST_PRIVATE = 1;
export const SHOPPING_LIST_PUBLIC = 2;

export const IS_XMAS_THEME = true;
