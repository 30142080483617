import {
  LoadingOutlined,
  // CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { ceil, chunk, get, isEmpty, map, slice } from "lodash";

import styles from "./Search.module.scss";
import Form from "antd/lib/form/Form";
import { useRouter } from "next/router";

import TopProduct from "components/autocomplete/TopProduct";
import ProductRow from "components/autocomplete/ProductRow";
import { createRef, useEffect, useRef, useState } from "react";

const Results = ({
  products,
  brands,
  categories,
  onSearchClick,
  onCloseClick,
}) => {
  // const topProducts = slice(products, 0, 2);
  // const otherProducts = slice(products, 2, 7);
  return (
    <div className={styles?.results}>
      <div className={styles?.resultsWrapper}>
        {/* <span className={styles?.close} onClick={onCloseClick}>
          <CloseCircleOutlined />
        </span> */}
        {/* <section className={styles?.topProducts}>
          <h2>Top produkty</h2>
          <div className={styles.topProductsWrapper}>
            {map(topProducts, (topProduct) => (
              <TopProduct
                {...topProduct}
                key={`search-top-product-${topProduct?.id}`}
              />
            ))}
          </div>
        </section> */}
        <section className={styles?.resultsLeft}>
          <h2>Nájdené produkty</h2>
          {/* {map(otherProducts, (product) => ( */}
          {map(products, (product) => (
            <ProductRow
              key={`autosuggest-product-${product?.id}`}
              {...product}
            />
          ))}
          <Button type="primary" onClick={onSearchClick}>
            Zobraziť všetky výsledky
          </Button>
        </section>
        <section className={styles?.resultsRight}>
          <h2>Nájdené kategórie</h2>
          <div className={styles?.categoriesWrapper}>
            {map(
              chunk(categories, ceil(categories.length / 2)),
              (categoryChunk) => (
                <ul>
                  {map(categoryChunk, (category) => (
                    <li key={`autosuggest-category-${category?.urlName}`}>
                      <a href={`/kategoria/${category?.urlName}`}>
                        {category?.name}
                      </a>
                    </li>
                  ))}
                </ul>
              )
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

const Search = ({ isVisible = false, setSearchVisible }) => {
  const router = useRouter();
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [typing, setTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [results, setResults] = useState([]);

  const searchInputRef = createRef();
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, isFocused]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (!isEmpty(results)) {
      body.classList.add('no-scroll');
    } else {
      body.classList.remove('no-scroll');
    }

    return () => {
      body.classList.remove('no-scroll');
    }
  }, [results])

  const fetchResults = async (value) => {
    setLoading(true);
    const data = await fetch(
      `/api/lib/v1/autosuggest?search=${encodeURIComponent(value)}`
    ).then((result) => result.json());
    setResults(data);
    setLoading(false);
  };

  const handleClose = () => {
    setValue("");
    setResults([]);
  };

  const handleChange = (e) => {
    const value = get(e, "target.value", "");
    setIsFocused(true);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setValue(value);
    setTyping(true);
    setTypingTimeout(
      setTimeout(() => {
        setTyping(false);
        if (value.length > 0) {
          fetchResults(value);
        } else {
          setResults([]);
        }
      }, 1000)
    );
  };

  const handleSubmit = () => {
    if (value.length > 0) {
      router.push({
        pathname: "/vyhladavanie/[term]/[[...parameters]]",
        query: { term: encodeURIComponent(value) },
      });
      setResults([]);
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleClickOutside = (e) => {;
    if (
      isVisible &&
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target)
    ) {
      handleClose(true);
    }
  };

  useEffect(() => {
    if (isVisible) {
      searchInputRef.current.focus();
    }
  }, [isVisible]);

  return (
    <>
      {isVisible ? (
        <div className={styles["wrapper"]} ref={wrapperRef}>
          <div className={styles["search-wrapper"]}>
            <div className={styles?.formWrapper}>
              <Form onFinish={handleSubmit}>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="term"
                  type="text"
                  value={value}
                  ref={searchInputRef}
                />
                {!isEmpty(value) ? (
                  <span className={styles?.clearInput} onClick={handleClose}>
                    <CloseOutlined />
                  </span>
                ) : null}
                <button type="submit" className={styles?.submit}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      display: "inline-block",
                    }}
                  >
                    {typing || loading ? (
                      <LoadingOutlined spin />
                    ) : (
                      <svg
                        height={20}
                        width={20}
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>search</title>
                        <g>
                          <path d="M27.166 27.461c.388-.177.59-.446.666-.817v-.033l.008-.034v-.202a.775.775 0 0 1-.042-.143c-.025-.076-.042-.135-.06-.177a1.109 1.109 0 0 0-.285-.337l-2.518-2.206c-.842-.741-1.684-1.466-2.501-2.198-.085-.076-.21-.177-.236-.37-.009-.186.11-.304.194-.397a9.785 9.785 0 0 0 2.332-3.84 9.205 9.205 0 0 0 .388-3.79c-.203-1.944-.96-3.696-2.24-5.203-1.44-1.685-3.293-2.83-5.508-3.41a9.86 9.86 0 0 0-2.088-.312L13.735 4c-1.634.135-3.15.59-4.514 1.381-2.139 1.221-3.655 2.947-4.53 5.12a8.849 8.849 0 0 0-.607 2.779v.025c-.008.657-.008 1.162.034 1.348v.008c.236 1.903.834 3.495 1.836 4.842 1.355 1.81 3.174 3.082 5.431 3.79 1.255.387 2.594.547 3.941.454 1.6-.11 3.116-.539 4.514-1.296.236-.127.463-.11.665.075.876.767 1.769 1.55 2.62 2.308.858.758 1.76 1.532 2.643 2.299.278.244.489.362.733.404.025 0 .042.008.067.008h.245c.025-.008.05-.008.084-.017a.964.964 0 0 0 .27-.067zm-10.922-5.945c-.59.11-1.179.168-1.751.168-2.013 0-3.832-.673-5.44-2.03A7.368 7.368 0 0 1 6.5 15.428c-.396-1.903-.11-3.713.901-5.372 1.12-1.878 2.804-3.116 4.985-3.722a8.438 8.438 0 0 1 3.369-.21c1.793.243 3.36.984 4.657 2.205a7.68 7.68 0 0 1 2.282 4c.11.472.16.952.16 1.449-.009 2.114-.767 3.95-2.257 5.431a8.093 8.093 0 0 1-4.354 2.308z" />
                        </g>
                      </svg>
                    )}
                  </div>
                </button>
              </Form>
              {/* {!isEmpty(results) ? (
                <Button
                  className={styles?.closeButton}
                  type="default"
                  icon={<CloseOutlined />}
                  onClick={handleClose}
                />
              ) : null} */}
            </div>
            {!isEmpty(results) ? (
              <Results
                {...results}
                onCloseClick={handleClose}
                onSearchClick={handleSubmit}
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Search;
