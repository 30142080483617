import useSWR from "swr";
import { get, map } from "lodash";
import Link from "next/link";
import { getCategoryLinkAttributes } from "grandus-lib/hooks/useFilter";

import {
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
} from "components/_others/icons";

import styles from "./Footer.module.scss";
import NewsletterForm from "components/forms/Newsletter";
import { Col, Row } from "antd";
import {IS_XMAS_THEME} from "../../constants/AppConstants";
import ImageNext from "next/image";

const Footer = () => {
  const { data } = useSWR(
    "/api/pages/footer",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const webinstance = get(data, "webinstance", {});
  const statics = get(data, "statics", []);
  const categories = get(data, "categories", []);

  return (
    <>
      {/* <div className={styles.newsletter}>
        <div className={styles.container}>
          <Row align="middle" justify="center">
            <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }}>
              <NewsletterForm />
            </Col>
          </Row>
        </div>
      </div> */}
      <footer id={styles.footer} className={IS_XMAS_THEME ? styles?.xmasFooter : ""}>
        {IS_XMAS_THEME ? (
          <ImageNext src={"/xmas/snow_footer.png"} layout={'fill'} quality={100} objectFit={"cover"} />
        ) : ""}
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.col}>
              <a href="https://www.freshbox.sk">
                {IS_XMAS_THEME ? (
                  <ImageNext src={"/xmas/footer-logo-red.jpeg"} width={144} height={145} quality={100} />
                ) : (
                  <img
                    src="https://instance12.admin.grandus.sk/files/attachment/2020-02/3982/66f7b3/footer-logo.png"
                    alt="Freshbox logo footer"
                    className={styles["footer-logo"]}
                  />
                )}
              </a>
            </div>
            <div className={styles.col}>
              <h3>Kontakt</h3>

              <a
                href={`mailto:${webinstance?.adminEmail}`}
                className={styles.email}
              >
                {webinstance?.adminEmail}
              </a>
              <a
                href={`tel:${get(webinstance, "globalSettings.phone")}`}
                className={styles.phone}
              >
                {get(webinstance, "globalSettings.phone")}
              </a>

              <address>
                {get(webinstance, "globalSettings.name_of_company")}
                <br />
                {get(webinstance, "globalSettings.street_and_number")}
                <br />
                {get(webinstance, "globalSettings.ZIP")}{" "}
                {get(webinstance, "globalSettings.city")} <br />
              </address>

              <div className={styles["social-icons"]}>
                {get(webinstance, "globalSettings.instagram_link") ? (
                  <a href={get(webinstance, "globalSettings.instagram_link")}>
                    <InstagramIcon height={24} width={24} />
                  </a>
                ) : (
                  ""
                )}

                {get(webinstance, "globalSettings.facebook_link") ? (
                  <a href={get(webinstance, "globalSettings.facebook_link")}>
                    <FacebookIcon height={24} width={24} />
                  </a>
                ) : (
                  ""
                )}

                {get(webinstance, "globalSettings.youtube_link") ? (
                  <a href={get(webinstance, "globalSettings.youtube_link")}>
                    <YoutubeIcon height={24} width={24} />
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={styles.col}>
              <h3>Informácie</h3>
              <ul>
                {map(statics, (item, index) => {
                  return (
                    <li key={`footer-static-page-${item?.id}-${index}`}>
                      <a href={"/stranka/" + get(item, "urlTitle")}>
                        {get(item, "title")}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={styles.col}>
              <h3>Ponuka</h3>
              {categories ? (
                <ul>
                  {map(categories, (category, index) => {
                    return (
                      <li key={`footer-category-${category?.id}-${index}`}>
                        {category?.externalUrl ? (
                          <a href={category?.externalUrl}>{category?.name}</a>
                        ) : (
                          <Link
                            {...getCategoryLinkAttributes(
                              get(category, "urlName")
                            )}
                            scroll={true}
                          >
                            <a>{get(category, "name")}</a>
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </footer>
      <div id={styles.copyright}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.col}>
              {new Date().getFullYear()} ©{" "}
              {get(webinstance, "globalSettings.name_of_company")} |
              prevádzkované eshop systémom{" "}
              <a
                href="https://www.grandus.sk"
                rel="nofollow sponsored"
                rel="noopener"
                target="_blank"
              >
                Grandus
              </a>{" "}
              od spoločnosti{" "}
              <a
                href="https://www.forbestclients.com"
                rel="nofollow sponsored"
                rel="noopener"
                target="_blank"
              >
                For Best Clients, s.r.o.
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
