import useSWR from "swr";
import { useRouter } from "next/router";

import { productPage } from "grandus-lib/utils/fetches";
import EnhancedEcommerce from "utils/ecommerce";
import TagManager from "grandus-lib/utils/gtag";
import { reqGetHeadersFront } from "grandus-lib/utils";

import ProductCommon from "components/product/detail/variant/common";
import ProductRecipe from "components/product/detail/variant/recipe";
import ProductBox from "components/product/detail/variant/box";

import MetaData from "grandus-lib/components-atomic/MetaData";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import MicroData, { MICRODATA_PRODUCT } from "grandus-lib/components-atomic/MicroData";

import { get } from "lodash";
import { useEffect } from "react";
import Remarketing from "grandus-lib/utils/remarketing";

const VARIANT_BOX = 70;
const VARIANT_RECIPE = 106;

export const ProductVariant = ({ product }) => {
  const productKindId = get(product, "kind.id");

  if (productKindId == VARIANT_BOX) {
    return <ProductBox product={product} />;
  }

  if (productKindId == VARIANT_RECIPE) {
    return <ProductRecipe product={product} />;
  }

  return <ProductCommon product={product} />;
};

const Product = (props) => {
  const router = useRouter();
  const { data: product, error } = useSWR(
    `/api/lib/v1/products/${get(router, "query.id")}?hash=${get(
      router,
      "query.hash"
    )}`,
    (url) =>
      fetch(url, {
        headers: reqGetHeadersFront(null, { forwardUrl: router.asPath }),
      }).then((r) => r.json()),
    {
      initialData: props.product,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    TagManager.push(EnhancedEcommerce.detail(props?.product));
    TagManager.push(Remarketing.viewItem(props?.product));
  }, []);

  return (
    <div className={"container guttered"}>
      <MetaData
        title={get(product, "name")}
        {...get(product, "meta", {})}
        photo={get(product, "photo", {})}
      />
      <MicroData type={MICRODATA_PRODUCT} data={product} />
      <Breadcrumbs
        data={get(product, "breadcrumbs", [])}
        current={get(product, "name")}
      />
      <ProductVariant product={product} />
    </div>
  );
};

export async function getServerSideProps(context) {
  return productPage.serverSideProps(context);
}

export default Product;
