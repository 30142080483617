import { get } from "lodash";

import { Button } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";

import AddToCartButtonBase from "components/cart/AddToCartButtonBase";

const AddToCartButtonButtonOnly = ({
  productId,
  stores,
  extra,
  selectedSize = null,
  defaultCount = 1,
  options = {},
}) => {
  return (
    <AddToCartButtonBase
      productId={productId}
      stores={stores}
      selectedSize={selectedSize}
      options={options}
      defaultCount={defaultCount}
      render={({ handleAddToCart, loading }) => (
        <>
          <Button
            type="primary"
            loading={loading}
            onClick={(e) => handleAddToCart(e)}
            icon={<ShoppingCartOutlined />}
          >
            {get(options, "buttonText") ? get(options, "buttonText") : null}
          </Button>
          {extra}
        </>
      )}
    />
  );
};

export default AddToCartButtonButtonOnly;
