import { forEach, isEmpty, map, orderBy, uniqBy } from "lodash";

import ProductCard from "components/product/card/ProductCard";
import { Carousel } from "antd";
import {
  ArrowLeft,
  ArrowRight,
} from "components/_others/carouselArrows/CarouselArrows";

import styles from "components/product/detail/recommendedProducts/RecommendedProducts.module.scss";

const RecommendedProducts = ({ product }) => {
  if (isEmpty(product)) {
    return null;
  }

  const carouselProducts = [];
  if (product?.relatedProducts) {
    carouselProducts.push(...product?.relatedProducts);
  }

  if (product?.crosssaleProducts) {
    let crosssaleProducts = [];
    forEach(product?.crosssaleProducts, (crosssale) => {
      if (crosssale?.products) {
        crosssaleProducts.push(...crosssale?.products);
      }
    });
    crosssaleProducts = orderBy(
      uniqBy(crosssaleProducts, "id"),
      ["priority", () => Math.random()],
      ["desc", "desc"]
    );
    carouselProducts.push(...crosssaleProducts);
  }

  if (isEmpty(carouselProducts)) {
    return null;
  }

  const settings = {
    dots: false,
    infinite: false,
    // speed: 1000,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <ArrowLeft customClassName={styles?.prevArrow} />,
    nextArrow: <ArrowRight customClassName={styles?.nextArrow} />,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <Carousel {...settings}>
      {map(carouselProducts, (product, index) => (
        <div className={styles?.slide} key={`products-${product.id}-${index}`}>
          <ProductCard {...product} options={{ hideDescription: true }} />
        </div>
      ))}
    </Carousel>
  );
};

export default RecommendedProducts;
