// import 'scripts/wdyr';
import "../styles/custom-antd.css";
import "../styles/custom-grid.scss";
import "../components/header/Header.scss";
import "../styles/global.scss";

import { useEffect, useMemo } from "react";

import Head from "next/head";
import Router from "next/router";
import TagManager from "grandus-lib/utils/gtag";
import FBPixel from "grandus-lib/utils/fbpixel";

import InfoBox from "components/InfoBox";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import ProductModal from "components/product/modal";

import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";

import NProgress from "nprogress";
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// export function reportWebVitals(metric) {
//   if (metric.label === "custom") {
//     console.info(`⏱️ | ${metric.name} - ${metric.value}ms`);
//   }
// }

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const GrandusApp = ({ Component, pageProps }) => {
  useEffect(() => {
    TagManager.registerPageViewTracking(Router);
    FBPixel.registerPageViewTracking(Router);
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <link rel="icon" href="/favicon.ico" />
        <meta name="apple-mobile-web-app-title" content="freshbox.sk" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#55ab2f" />
        <meta name="msapplication-TileColor" content="#55ab2f" />
        <meta name="theme-color" content="#55ab2f"></meta>
        <meta
          name="facebook-domain-verification"
          content="fabzp7mmp9670l78u3qh9psl6kn5ts"
        />
      </Head>

      <ProductModal />
      <div className="sticky">
        {useMemo(
          () => (
            <>
              <InfoBox />
              <Header />
            </>
          ),
          []
        )}
      </div>
      <Component {...pageProps} />
      {useMemo(
        () => (
          <Footer />
        ),
        []
      )}
    </>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default GrandusApp;
