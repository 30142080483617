import { get, round, groupBy, map } from "lodash";
import ProductLabels from "components/product/card/ProductLabels";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";

import styles from "./index.module.scss";
import dynamic from "next/dynamic";
const CrossSale = dynamic(() => import("components/product/detail/crossSale"), {
  ssr: false,
});
import Gallery from "components/gallery/GallerySlider";

import { Tabs, Carousel } from "antd";
const { TabPane } = Tabs;

//PARTIALS
import PartialAddToCart from "components/product/detail/_partials/PartialAddToCart";
import RecommendedProducts from "components/product/detail/recommendedProducts/RecommendedProducts";

const Product = ({ product }) => {
  const storeStatus = get(product, "storeStatus");
  const groupedParameters = groupBy(
    get(product, "detailedParameters", []),
    (parameter) => parameter.group
  );
  return (
    <>
      <div
        id={get(styles, "product-detail")}
        className={product.isOrderable ? "" : styles.nonAvailable}
      >
        <div className={styles["container"]}>
          <div className={styles["row"]}>
            <div className={styles["col"]}>
              <div className={styles["gallery-wrapper"]}>
                <Gallery
                  titleImage={get(product, "photo", null)}
                  images={get(product, "gallery", [])}
                  options={{
                    imageMainProps: {
                      size: "580x375",
                      className: product.isOrderable ? "" : "nonAvailable",
                      useNextImage: true
                    },
                    imageGalleryProps: {
                      size: "580x375__cropped",
                      className: product.isOrderable ? "" : "nonAvailable",
                      useNextImage: true
                    },
                  }}
                />
              </div>
            </div>
            <div className={styles["col"]}>
              <h1>{product?.name}</h1>
              <div className={styles["subtitle"]}>{product?.subtitle}</div>

              <div className={styles.breadcrumbsWrapper}>
                <Breadcrumbs
                  data={get(product, "breadcrumbs", [])}
                  current={get(product, "name")}
                  options={{
                    hideHomeItem: true,
                    noWrapper: true,
                  }}
                />
              </div>

              <ProductLabels {...{ ...product, inline: true }} />

              <div
                className={get(styles, "short-description")}
                dangerouslySetInnerHTML={{
                  __html: get(product, "shortProductDescription.description"),
                }}
              />

              {product.isOrderable ? (
                <div className={styles["availability"]}>
                  {storeStatus?.available ? (
                    <span
                      className={`${styles.availability} ${styles.available}`}
                    >
                      {get(storeStatus, "message")}
                    </span>
                  ) : (
                    <span
                      className={`${styles.availability} ${
                        get(storeStatus, "deliveryDate.date")
                          ? styles.arriving
                          : styles.unavailable
                      }`}
                    >
                      {get(storeStatus, "deliveryDate.date") ? "Očakávame" : ""}{" "}
                      {get(storeStatus, "message")}
                    </span>
                  )}
                </div>
              ) : null}
              <PartialAddToCart product={product} styles={styles} />
            </div>
          </div>

          <div className={`${styles["container"]} ${styles["gutter-bottom"]}`}>
            <div className={styles["row"]}>
              <div className={`${styles["col"]} ${styles["col-12"]}`}>
                <div className={`freshbox-tabs ${styles?.tabs}`}>
                  <Tabs type="card" defaultActiveKey={1}>
                  {true || get(product, "advantages.description", null) ? (
                      <TabPane tab={"Odporúčané produkty"} key={1}>
                        <RecommendedProducts product={product} />
                      </TabPane>
                    ) : (
                      ""
                    )}
                    <TabPane tab={"Čo je obsahom?"} key={2}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: get(
                            product,
                            "productDescription.description",
                            "Detailnejší opis produktu pripravujeme..."
                          ),
                        }}
                      />

                      {map(groupedParameters, (group, label) => {
                        return (
                          <div
                            className={styles["parameters"]}
                            key={`detailed-parameter-wrapper-${
                              group.length
                            }-${encodeURIComponent(label)}`}
                          >
                            {label != "null" ? <h3>{label}</h3> : ""}

                            {map(
                              groupBy(
                                group,
                                (groupItem) => groupItem.parameterId
                              ),
                              (item, index) => {
                                let groupped = [];

                                map(item, (row) =>
                                  groupped.push(get(row, "value"))
                                );

                                return (
                                  <div
                                    className={styles["parameter"]}
                                    key={`detailed-parameter-${index}-${groupped.length}`}
                                  >
                                    <h4>{get(item, "[0].name")}</h4>
                                    <p>{groupped.join(", ")}</p>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      })}
                    </TabPane>
                    {get(product, "advantages.description", null) ? (
                      <TabPane tab={"Informácie o produkte"} key={3}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: get(
                              product,
                              "advantages.description",
                              "Nie sú k dispozícií žiadne informácie..."
                            ),
                          }}
                        />
                      </TabPane>
                    ) : (
                      ""
                    )}
                    {map(get(product, "additionalInfos", []), (info) => {
                      return (
                        <TabPane tab={info?.title} key={info?.id}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: get(info, "text", ""),
                            }}
                          />
                        </TabPane>
                      );
                    })}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={styles.container}>
        <div className={styles.col}>
          <CrossSale data={get(product, "crosssaleProducts", [])} />
        </div>
      </div> */}
    </>
  );
};

export default Product;
