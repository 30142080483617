import { isEmpty, first, drop, get, concat } from "lodash";
import Lightbox from "react-image-lightbox";
import Image from "grandus-lib/components-atomic/image/Image";

import "react-image-lightbox/style.css";
import styles from "./Gallery.module.scss";
import { useState } from "react";

const imageHost = process.env.NEXT_PUBLIC_IMAGE_HOST
  ? process.env.NEXT_PUBLIC_IMAGE_HOST
  : "";

const generateGalleryImageObject = (image = null) => {
  if (!image) {
    return null;
  }
  return {
    caption: image.title ? image.title : "",
    path: `${image?.path}`,
    src: `${imageHost}${image?.path}/1200x800.jpg`,
    updateTime: get(image, "updateTime"),
  };
};

const Gallery = ({ images, titleImage = null, showThumbnails = false, options = {} }) => {
  if (isEmpty(images) && titleImage == null) {
    return null;
  }
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  let photos = images.map((image) => generateGalleryImageObject(image));
  let mainPhoto = generateGalleryImageObject(titleImage);
  if (!mainPhoto) {
    mainPhoto = first(photos);
    photos = drop(photos, 1);
  }
  const allPhotos = concat(mainPhoto, photos);
  const updateTimeTimestamp = mainPhoto.updateTime
    ? "?v=" + Date.parse(mainPhoto.updateTime)
    : "";
  return (
    <div className={styles?.gallery}>
      <a
        className={styles?.mainImage}
        href={mainPhoto.src + updateTimeTimestamp}
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
      >
        <Image photo={mainPhoto} size={"600x500__cropped"} type={"jpg"} {...get(options, 'imageMainProps', {})} />
      </a>
      {showThumbnails && !isEmpty(photos) ? (
        <div className={styles?.thumbnails}>
          {photos.map((photo, index) => (
            <a
              key={`thumbnail-${index}`}
              href={photo.src}
              onClick={(e) => {
                e.preventDefault();
                setPhotoIndex(index + 1);
                setIsOpen(true);
              }}
            >
              <Image photo={photo} size={"100x100__cropped"} type={"jpg"} {...get(options, 'imageThumbnailProps', {})} />
            </a>
          ))}
        </div>
      ) : null}
      {isOpen ? (
        <Lightbox
          mainSrc={allPhotos[photoIndex].src}
          nextSrc={allPhotos[(photoIndex + 1) % allPhotos.length].src}
          prevSrc={
            allPhotos[(photoIndex + allPhotos.length - 1) % allPhotos.length]
              .src
          }
          onCloseRequest={() => {
            setIsOpen(false);
            setPhotoIndex(0);
          }}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + allPhotos.length - 1) % allPhotos.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % allPhotos.length)
          }
        />
      ) : null}
    </div>
  );
};

export default Gallery;
