import { useEffect, useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import find from "lodash/find";
import isFunction from "lodash/isFunction";

import useCart from "grandus-lib/hooks/useCart";
import { Select, Radio, message } from "antd";
import EnhancedEcommerce from "utils/ecommerce";
import TagManager from "grandus-lib/utils/gtag";
import { useRouter } from "next/router";
import Remarketing from "grandus-lib/utils/remarketing";

const { Option } = Select;

export const PartSize = ({
  stores,
  productId,
  selectedValue,
  handleChange,
  forceSelectbox = false,
  hideSingle = false
}) => {
  
  if (hideSingle && stores.length == 1) {
    return null;
  }

  if (!forceSelectbox && !hideSingle && stores.length == 1) {
    return get(stores, "[0].name");
  }

  if (!forceSelectbox && stores.length <= 4) {
    return (
      <Radio.Group
        onChange={(e) => handleChange(e.target.value)}
        value={selectedValue}
      >
        {stores.map((store, index) => (
          <Radio
            key={`product-${productId}-store-${get(
              store,
              "id"
            )}-option-${index}`}
            value={get(store, "id")}
          >
            {get(store, "name")}
          </Radio>
        ))}
      </Radio.Group>
    );
  } else {
    return (
      <Select
        placeholder={"Vyberte možnosť"}
        onChange={(value) => handleChange(value)}
        value={selectedValue}
      >
        {stores.map((store, index) => (
          <Option
            key={`product-${productId}-store-${get(
              store,
              "id"
            )}-option-${index}`}
            value={get(store, "id")}
          >
            {get(store, "name")}
          </Option>
        ))}
      </Select>
    );
  }
};

const AddToCartButtonBase = ({
  render,
  productId,
  stores,
  defaultCount = 1,
  selectedSize = null,
  sizeChangeCallback,
  options = {},
}) => {

  const router = useRouter();
  const { itemAdd } = useCart();

  const [count, setCount] = useState(defaultCount);
  const [store, setStore] = useState(selectedSize || get(stores, "[0].id"));
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  async function handleAddToCart(e) {
    setLoading(true);
    setErrors(null);
    const options = {};
    if (router?.query?.hash) {
      options.hash = get(router, "query.hash");
    }
    itemAdd(
      count,
      store,
      productId,
      (response) => {
        if (response?.success) {
          const item = find(response?.items, (item) => {
            return (
              item?.store?.id === +store && item?.product?.id === +productId
            );
          });
          if (item) {
            TagManager.push(EnhancedEcommerce.cartAdd(item?.product, count));
            TagManager.push(Remarketing.addToCart(item?.product));
          }
        } else if (!response?.success && !isEmpty(response?.messages)) {
          setErrors(
            filter(response?.messages, (message) => !isEmpty(message?.field))
          );
        }
        setLoading(false);
      },
      options
    );
    return false;
  }

  useEffect(() => {
    if (!isEmpty(errors) && options?.useToastr) {
      forEach(errors, (error) => {
        message.error(error.message);
      });
    }
  }, [errors]);

  useEffect(() => {
    setCount(defaultCount);
  }, [defaultCount]);

  if (isEmpty(stores)) {
    return "Tento produkt aktuálne nie je dostupný, čoskoro ho pre Vás naskladníme.";
  }

  return render({
    setCount,
    loading,
    handleAddToCart,
    errors,
    partSize: (
      <PartSize
        stores={stores}
        productId={productId}
        selectedValue={store}
        handleChange={(value) => {
          setStore(value);
          if (isFunction(sizeChangeCallback)) {
            sizeChangeCallback(value);
          }
        }}
      />
    ),
    options,
  });
};

export default AddToCartButtonBase;
