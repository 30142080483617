import * as yup from "yup";
import { Form, Button, Input, Radio, Typography } from "antd";
import { Formik } from "formik";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import router from "next/router";

import useShoppingList from "grandus-lib/hooks/useShoppingList";

import TextInput from "grandus-lib/components-atomic/form/TextInput";
import FloatLabel from "grandus-lib/components-atomic/form/label/FloatLabel";
import {
  SHOPPING_LIST_PRIVATE,
  SHOPPING_LIST_PUBLIC,
} from "constants/AppConstants";

const { TextArea } = Input;

import styles from "components/shoppingList/AddNewList.module.scss";

const AddForm = ({ list = null, onCreateCallback, onUpdateCallback }) => {
  const { create, update, remove } = useShoppingList();
  const url = typeof window !== undefined ? new URL(window.location) : null;
  const shareableLink =
    url && list
      ? `${url?.origin}/ucet/nakupny-zoznam/${list?.accessToken}`
      : null;
      
  const formProps = {
    enableReinitialize: true,
    initialValues: {
      name: list?.name || "",
      note: list?.note || "",
      type: list?.type || SHOPPING_LIST_PRIVATE,
    },
    validationSchema: yup.object({
      name: yup.string().nullable().trim().required("Povinné pole"),
      note: yup.string().nullable().trim(),
      type: yup.number().required("Vyberte typ zoznamu."),
    }),
    onSubmit: async (values, { errors, setFieldError, setErrors }) => {
      try {
        if (!isEmpty(list)) {
          await update(list?.accessToken, { ...values }, onUpdateCallback);
        } else {
          await create({ ...values }, onCreateCallback);
        }
      } catch (error) {
        console.error("An unexpected error happened:", error);
        setErrors(error.data.message);
      }

      return false;
    },
  };

  return (
    <Formik {...formProps}>
      {({
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form
          wrapperCol={{ span: 24 }}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <TextInput
            label="Názov"
            placeholder="Názov, napr. Letná grilovačka"
            name="name"
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />

          <FloatLabel
            label="Popis"
            placeholder="Popis, čoho sa týka tento konkrétny zoznam"
            name="note"
            value={get(values, "note")}
          >
            <TextArea
              id="note"
              name="note"
              value={get(values, "note")}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FloatLabel>

          <Form.Item>
            <Radio.Group
              name="type"
              onChange={handleChange}
              value={get(values, "type")}
            >
              <Radio value={SHOPPING_LIST_PRIVATE}>Súkromný</Radio>
              <Radio value={SHOPPING_LIST_PUBLIC}>Verejný</Radio>
            </Radio.Group>
          </Form.Item>

          {!isEmpty(list) && url ? (
            <Typography.Paragraph
              className={styles?.shareableLink}
              copyable={{
                text: shareableLink,
                icon: [
                  <Button
                    type="primary"
                    size="large"
                    disabled={list?.type === SHOPPING_LIST_PRIVATE}
                    className={styles?.copyButton}
                  >
                    Kopírovať
                  </Button>,
                  <Button
                    type="primary"
                    size="large"
                    disabled
                    className={styles?.copyButton}
                  >
                    Úspešné
                  </Button>,
                ],
                tooltips: [false, false],
              }}
            >
              <span>{shareableLink}</span>
            </Typography.Paragraph>
          ) : null}

          <Button
            className={list ? styles?.saveBtn : ""}
            type={list ? "text" : "primary"}
            htmlType="submit"
            loading={isValid && isSubmitting}
          >
            Uložiť
          </Button>
          {!isEmpty(list) ? (
            <Button
              className={list ? styles?.deleteBtn : ""}
              type="text"
              onClick={() => remove(list?.accessToken, () => {
                router.push('/ucet/nakupny-zoznam');
              })}
            >
              Zmazať zoznam
            </Button>
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

export default AddForm;
