import { useState } from "react";

import isEmpty from "lodash/isEmpty";
import first from "lodash/first";
import drop from "lodash/drop";
import get from "lodash/get";
import concat from "lodash/concat";

import Lightbox from "react-image-lightbox";
import { Carousel } from "antd";
import {
  ArrowLeft,
  ArrowRight,
} from "components/_others/carouselArrows/CarouselArrows";

import Image from "grandus-lib/components-atomic/image/Image";

import "react-image-lightbox/style.css";
import styles from "./GallerySlider.module.scss";

const imageHost = process.env.NEXT_PUBLIC_IMAGE_HOST
  ? process.env.NEXT_PUBLIC_IMAGE_HOST
  : "";

const generateGalleryImageObject = (image = null) => {
  if (!image) {
    return null;
  }
  return {
    caption: image.title ? image.title : "",
    path: `${image?.path}`,
    src: `${imageHost}${image?.path}/1200x800.jpg`,
    updateTime: get(image, "updateTime"),
  };
};

const carouselSettings = {
  false: true,
  arrows: true,
  speed: 1000,
  slidesToScroll: 1,
  slidesToShow: 1,
  prevArrow: <ArrowLeft />,
  nextArrow: <ArrowRight />,
};

const GallerySlider = ({ images, titleImage = null, options = {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  if (isEmpty(images) && titleImage == null) {
    return null;
  }
  let photos = images.map((image) => generateGalleryImageObject(image));
  let mainPhoto = generateGalleryImageObject(titleImage);
  if (!mainPhoto) {
    mainPhoto = first(photos);
    photos = drop(photos, 1);
  }
  
  const allPhotos = concat(mainPhoto, photos);
  const updateTimeTimestamp = mainPhoto.updateTime
  ? "?v=" + Date.parse(mainPhoto.updateTime)
  : "";
  
  return (
    <div className={styles?.gallery}>
      <Carousel {...carouselSettings}>
        <a
          className={styles?.mainImage}
          href={mainPhoto.src + updateTimeTimestamp}
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(true);
          }}
        >
          <Image
            useNextImage
            photo={mainPhoto}
            size={"600x500__cropped"}
            type={"jpg"}
            {...get(options, "imageMainProps", {})}
          />
        </a>
        {!isEmpty(photos)
          ? photos.map((photo, index) => (
              <a
                key={`thumbnail-${index}`}
                href={photo.src}
                onClick={(e) => {
                  e.preventDefault();
                  setPhotoIndex(index + 1);
                  setIsOpen(true);
                }}
              >
                <Image
                  useNextImage
                  key={`gallery-photo-${photo?.id}`}
                  photo={photo}
                  size={"600x500__cropped"}
                  type={"jpg"}
                  {...get(options, "imageGalleryProps", {})}
                />
              </a>
            ))
          : null}
      </Carousel>
      {isOpen ? (
        <Lightbox
          mainSrc={allPhotos[photoIndex].src}
          nextSrc={allPhotos[(photoIndex + 1) % allPhotos.length].src}
          prevSrc={
            allPhotos[(photoIndex + allPhotos.length - 1) % allPhotos.length]
              .src
          }
          onCloseRequest={() => {
            setIsOpen(false);
            setPhotoIndex(0);
          }}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + allPhotos.length - 1) % allPhotos.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % allPhotos.length)
          }
        />
      ) : null}
    </div>
  );
};

export default GallerySlider;
