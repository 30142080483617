import { RightOutlined, LeftOutlined } from "@ant-design/icons";

import styles from "./CarouselArrows.module.scss";

export const ArrowLeft = (props) => {
  const { className, small, onClick, customClassName, customStyle } = props;
  return (
    <button
      type="button"
      className={`${customClassName} ${className} ${styles.arrow} ${styles.left}`}
      style={customStyle}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <title>ic_keyboard_arrow_left_24px</title>
        <g>
          <path d="M20.547 21.453l-6.107-6.12 6.107-6.12-1.88-1.88-8 8 8 8z"></path>
        </g>
      </svg>
    </button>
  );
};

export const ArrowRight = (props) => {
  const { className, small, onClick, customClassName, customStyle } = props;
  return (
    <button
      type="button"
      className={`${customClassName} ${className} ${styles.arrow} ${styles.right}`}
      style={customStyle}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <title>ic_keyboard_arrow_right_24px</title>
        <g>
          <path d="M11.453 21.787l6.107-6.12-6.107-6.12 1.88-1.88 8 8-8 8z"></path>
        </g>
      </svg>
    </button>
  );
};