import { useState } from "react";

const useStack = () => {
  const [data, setData] = useState([]);
  const [top, setTop] = useState(0);

  const push = (element) => {
    const newData = [...data];
    newData.push(element);
    setTop(top+1);
    setData(newData);
  };
  const peek = () => {
    return data[top-1];
  };
  const isEmpty = () => {
    return top === 0;
  };
  const pop = () => {
    if (!isEmpty()) {
      const newData = [...data];
      const poppedElement = newData.pop(); // removes the last element
      setTop(top-1);
      setData(newData);
      return poppedElement;
    }
  };
  const print = () => {
    const newTop = top-1; // because top points to index where new element to be inserted
    while (newTop >= 0) {
      // print up to 0th index
      newTop--;
    }
  };
  const truncate = () => {
      setTop(0);
      setData([]);
  }

  return {
      data,
      push,
      pop,
      peek,
      length: top,
      isEmpty,
      print,
      truncate
  }
};

export default useStack;