import { LoadingOutlined } from "@ant-design/icons";

import styles from "components/_others/loader/Loader.module.scss";

const Loader = ({ isLoading = false }) => {
  return (
    <div className={`${styles?.loader} ${isLoading ? ''  : styles?.hidden}`}>
      <LoadingOutlined />
    </div>
  );
};

export default Loader;
