import { useState } from "react";
import { Button, Badge, Drawer } from "antd";
import useCart from "grandus-lib/hooks/useCart";
import { get } from "lodash";

import SidebarSummary from "components/cart/summary/SidebarSummary";
import Link from "next/link";
import { ShoppingCartOutlined, LoadingOutlined } from "@ant-design/icons";

const CartNextButton = ({ drawerMethod }) => {
  return (
    <div
      style={{
        height: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Link href={"/kosik"} as={"/kosik"}>
        <Button
          type={"primary"}
          style={{ height: "50px" }}
          onClick={() => drawerMethod(false)}
        >
          Pokračovať do košíka
        </Button>
      </Link>
    </div>
  );
};

const CartButton = () => {
  const { cart, isLoading } = useCart();
  const [visible, setVisible] = useState(false);
  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          const chatButton = document.getElementById('chat-application');
          if (chatButton) {
            chatButton.style.right = '350px'
          }
          setVisible(true);
        }}
      >
        <Badge count={get(cart, "pieceCount", 0)}>
          {isLoading ? (
            <LoadingOutlined className={"icon"} />
          ) : (
            <ShoppingCartOutlined className={"icon"} />
          )}
          <span style={{ marginLeft: "5px" }}>
            {get(cart, "sumData.priceFormatted", "")}
          </span>
        </Badge>
      </div>
      <Drawer
        title="Nákupný košík"
        placement="right"
        width={340}
        keyboard={true}
        maskClosable={true}
        closable={true}
        onClose={() => {
          const chatButton = document.getElementById('chat-application');
          if (chatButton) {
            chatButton.style.right = '11px'
          }
          setVisible(false);
        }}
        visible={visible}
        footer={<CartNextButton drawerMethod={setVisible} />}
      >
        <SidebarSummary />
      </Drawer>
    </>
  );
};

export default CartButton;
