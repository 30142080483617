import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import isFunction from "lodash/isFunction";

import { InputNumber, Button, Alert } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";

import AddToCartButtonBase from "components/cart/AddToCartButtonBase";

import styles from "components/cart/AddToCartButton.module.scss";

const AddToCartButton = ({
  productId,
  stores,
  amount = 1,
  onCountChange,
  selectedSize = null,
  options = {},
}) => {
  return (
    <AddToCartButtonBase
      productId={productId}
      stores={stores}
      selectedSize={selectedSize}
      options={options}
      render={({
        partSize,
        handleAddToCart,
        errors = null,
        setCount,
        loading,
        options: parentOptions,
      }) => (
        <>
          <div>
            <span>
              <InputNumber
                min={1}
                max={999}
                step={1}
                defaultValue={amount}
                onChange={(value) => {
                  setCount(value);
                  if (isFunction(onCountChange)) {
                    onCountChange(value);
                  }
                }}
                type="number"
              />
              {partSize}
            </span>
            <Button
              type="primary"
              loading={loading}
              onClick={(e) => handleAddToCart(e)}
              icon={<ShoppingCartOutlined />}
            >
              {parentOptions?.showText
                ? get(options, "buttonText")
                  ? get(options, "buttonText")
                  : "Objednať"
                : null}
            </Button>
          </div>
          {!isEmpty(errors) && !parentOptions?.useToastr ? (
            <Alert
              className={styles?.alert}
              type="error"
              message={map(errors, (error) => error?.message).join("\n")}
            />
          ) : null}
        </>
      )}
    />
  );
};

export default AddToCartButton;
