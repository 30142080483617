import { Modal } from "antd";
import useSWR from "swr";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { ProductVariant } from "pages/produkt/[id]";
import TagManager from "grandus-lib/utils/gtag";
import EnhancedEcommerce from "utils/ecommerce";
import isEmpty from "lodash/isEmpty";
import Remarketing from "grandus-lib/utils/remarketing";

export const getPopupInUrlPosition = (href) => {
  return href.indexOf("?popup=") > 0
    ? href.indexOf("?popup=")
    : href.indexOf("&popup=");
};

export const removePopupFromUrl = (href) => {
  const position = getPopupInUrlPosition(href);
  return position > 0 ? href.substr(0, position) : href;
};

export const addPopuptoUrl = (href, popupId) => {
  const hrefRemoved = removePopupFromUrl(href);
  return `${hrefRemoved}${
    hrefRemoved.indexOf("?") > 0 ? "&" : "?"
  }popup=${popupId}`;
};

const ProductModal = () => {
  const [openModal, setOpenModal] = useState(false);

  const router = useRouter();
  let urlToFetch = null;
  if (router.query?.popup) {
    urlToFetch = `/api/lib/v1/products/${router.query?.popup}`;
  } else {
    urlToFetch = null;
  }

  const { data: elastic } = useSWR(
    urlToFetch ? urlToFetch + "?initial=1" : null,
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      onSuccess: (data, key, config) => {
        setOpenModal(true);
      },
    }
  );

  const { data: mysql } = useSWR(
    urlToFetch,
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      onSuccess: (data, key, config) => {
        setOpenModal(true);
      },
    }
  );

  useEffect(() => {
    if (!isEmpty(elastic)) {
      TagManager.push(EnhancedEcommerce.detail(elastic));
      TagManager.push(Remarketing.viewItem(elastic));
    }
  }, [elastic]);

  const handleClose = () => {
    setOpenModal(false);
    const href = removePopupFromUrl(window?.location?.href);
    router.push(href, undefined, {
      scroll: false,
      shallow: true,
    });
  };

  return (
    <Modal
      title={"Vybraný produkt"}
      visible={openModal}
      width={"100%"}
      footer={null}
      closable={true}
      keyboard={true}
      maskClosable={true}
      onCancel={handleClose}
      onClose={handleClose}
      destroyOnClose={true}
    >
      {mysql?.id ? (
        <ProductVariant product={mysql} />
      ) : elastic?.id ? (
        <ProductVariant product={elastic} />
      ) : (
        "loadujem data"
      )}
    </Modal>
  );
};

export default ProductModal;
