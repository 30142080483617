import useSWR from "swr";
import { useState } from "react";
import { get } from "lodash";
import CartButton from "components/cart/CartButton";
import UserButton from "components/user/AuthButton";
import useWishlist from "grandus-lib/hooks/useWishlist";
import {
  MenuOutlined,
  HeartOutlined,
  SearchOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Search from "components/header/Search";
import Image from "grandus-lib/components-atomic/image/Image";
import Link from "next/link";

import Menu from "components/menu/Menu";
import Megamenu from "components/menu/megamenu/Megamenu";
import ImageNext from "next/image";
import dynamic from "next/dynamic";
import { Badge } from "antd";
import {IS_XMAS_THEME} from "../../constants/AppConstants";
const Transition = dynamic(() => import("pages/_transition"));
const DeliveryModal = dynamic(() => import("components/deliveryModal"), {
  ssr: false,
});

const Header = () => {
  const { data } = useSWR(
    "/api/pages/header",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const webinstance = get(data, "webinstance", {});
  const { wishlist, isLoading: isLoadingWishlist } = useWishlist();

  const [isSearchVisible, setSearchVisible] = useState(false);
  const [openedMenu, setOpenedMenu] = useState(false);

  const onClickToggleOpenMenu = () => {
    if (openedMenu) {
      setOpenedMenu(false);
    } else {
      setOpenedMenu(true);
    }
  };
  const updateOpenedMenu = (opened) => {
    if (openedMenu !== opened) {
      setOpenedMenu(opened);
    }
  };

  const menuData = {};
  menuData.options = {};
  menuData.options.disables = { compare: true };
  menuData.options.megamenu = { type: "auto" };
  menuData.options.logo = get(webinstance, "logo");
  if (menuData.options.logo) {
    menuData.options.logo.resolution = "/110x60";
  }
  menuData.updateOpenedMenu = updateOpenedMenu;
  menuData.isOpen = openedMenu;

  return (
    <div>
      <header id={"header"}>
        <div className={"container"} style={{ position: "relative" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={"logo"}>
              <Link href="/" as={`/`}>
                <a className={"logo-anchor"}>
                  {IS_XMAS_THEME ? (
                    <ImageNext src={"/xmas/logo-xmas.jpeg"} width={104} height={56} alt={"logo"} />
                  ) : (
                    <>
                      {get(webinstance, "logo") ? (
                        <Image
                          photo={get(webinstance, "logo")}
                          size={get(webinstance, "logo.id") + "/99x54"}
                          type={"png"}
                        />
                      ) : (
                        get(webinstance, "globalSettings.name_of_company")
                      )}
                    </>
                  )}
                </a>
              </Link>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <Transition>
              <Menu {...menuData} />
            </Transition>
            <div className={"menuItem menuToggle"}>
              <MenuOutlined
                onClick={onClickToggleOpenMenu}
                className={"icon"}
              />
            </div>
            <div className={"menuItem hidden-xs"}>
              <Link href="/wishlist" as={`/wishlist`}>
                <a>
                  {get(wishlist, "count", 0) ? (
                    <Badge
                      count={get(wishlist, "count", 0)}
                      className={"wishlistBadge"}
                    >
                      <HeartOutlined className={"icon"} />
                    </Badge>
                  ) : (
                    <HeartOutlined className={"icon"} />
                  )}
                </a>
              </Link>
            </div>
            <div className={"menuItem"}>
              {isSearchVisible ? (
                <CloseCircleOutlined
                  onClick={() => {
                    setSearchVisible(false);
                  }}
                  className={"icon"}
                />
              ) : (
                <SearchOutlined
                  onClick={() => {
                    setSearchVisible(true);
                  }}
                  className={"icon"}
                />
              )}
            </div>
            <div className={"menuItem hidden-xs"}>
              <UserButton />
            </div>
            <div className={"menuItem"}>
              <CartButton />
            </div>
          </div>
          <Search
            isVisible={isSearchVisible}
            setSearchVisible={setSearchVisible}
          />
        </div>
      </header>
      <div className="container">
        <Megamenu />
      </div>
    </div>
  );
};

export default Header;
