import get from "lodash/get";

import AddToCartButton from "components/cart/AddToCartButton";
import PartialWishlist from "components/product/detail/_partials/PartialWishlist";
import AddToShoppingListButton from "components/shoppingList/addButton/AddButton";
import { useState } from "react";

const PartialAddToCart = ({ product, styles = {} }) => {
  const [count, setCount] = useState(1);
  const [store, setStore] = useState(get(product, "store[0].id"));
  return (
    <div className={styles["add-to-cart"]}>
      <div className={styles["cart-button-wrapper"]}>
        <div className={styles["prices"]}>
          <div className={styles["selling-price"]}>
            {get(product, "finalPriceData.priceFormatted")}
          </div>
          {product?.discount ? (
            <div className={styles["origin-price"]}>
              {get(product, "priceData.priceFormatted")}
            </div>
          ) : (
            ""
          )}
        </div>
          {get(product, "minPriceInLastDays.priceFormatted")?
              <div className={styles.lowestPrice}>
                  Najnižšia cena za posledných 30 dní: <strong> {get(product, "minPriceInLastDays.priceFormatted")} </strong>
              </div>:""
          }
        {product.isOrderable ? (
          <AddToCartButton
            stores={get(product, "store", [])}
            productId={get(product, "id")}
            onCountChange={(value) => setCount(value)}
            onStoreChange={(value) => setStore(value)}
            options={{
              showText: false,
              // buttonText: "Vložiť do košíka",
              eecProduct: {
                name: get(product, "name"),
                id: get(product, "id"),
                finalPriceData: get(product, "finalPriceData"),
                brand: get(product, "brand"),
                categories: get(product, "categories"),
              },
            }}
          />
        ) : (
          <>
            <strong className={styles.alertText}>
              Tovar nie je aktuálne dostupný.
            </strong>
            <br />
            {get(
              get(product, "storeStatus"),
              "messageUnavailable",
              "Pracujeme na jeho naskladnení."
            )}
          </>
        )}
      </div>
      <br />
      <div className={styles?.buttons}>
        {/* <PartialWishlist
          productId={product?.id}
          styles={styles}
          options={{ hideText: true }}
        /> */}
        <AddToShoppingListButton
          product={product}
          count={count}
          store={store}
        />
      </div>
    </div>
  );
};

export default PartialAddToCart;
