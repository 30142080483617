import styles from "components/menu/megamenu/Megamenu.module.scss";
import {
  CATEGORY_HIGHLIGHTED_HASH,
  PRODUCTS_CATEGORY_HASH,
} from "constants/AppConstants";
import isEmpty from "lodash/isEmpty";
import slice from "lodash/slice";
import find from "lodash/find";
import map from "lodash/map";
import fill from "lodash/fill";
import useSWR from "swr";
import Link from "next/link";
import Image from "grandus-lib/components-atomic/image/Image";
import { useState } from "react";

const MobileLink = ({ category, isOpen, handleClick }) => (
  <a
    onClick={handleClick}
    className={`hidden-md-up ${isOpen ? styles?.open : ""} ${
      !isEmpty(category?.children) ? styles?.hasChild : ""
    }`}
  >
    {category.name}
  </a>
);

const DesktopLink = ({ category }) => (
  <Link
    href={
      category?.externalUrl
        ? category.externalUrl
        : `/kategoria/${category.urlName}`
    }
  >
    <a className="hidden-md-down">{category.name}</a>
  </Link>
);

const MenuLevel3 = ({ parent, isOpen }) => {
  const { children: categories } = parent;
  if (!categories) {
    return null;
  }

  const slicedCategories = slice(categories, 0, 4);
  return (
    <ul className={`${styles?.thirdLevel} ${isOpen ? styles?.active : ""}`}>
      {map(slicedCategories, (category) => (
        <li key={`category-${category?.id}`}>
          <Link
            href={
              category?.externalUrl
                ? category.externalUrl
                : `/kategoria/${category.urlName}`
            }
          >
            <a>{category.name}</a>
          </Link>

          {category?.mainPhoto ? (
            <div className={styles.categoryImage}>
              <Image
                photo={category?.mainPhoto}
                size="35x35"
                type={"png"}
                useNextImage={false}
              />
            </div>
          ) : null}
        </li>
      ))}
      {categories.length > 4 ? (
        <li>
          <Link
            href={
              parent?.externalUrl
                ? parent.externalUrl
                : `/kategoria/${parent.urlName}`
            }
          >
            <a className={`${styles?.primary} ${styles?.showMore}`}>
              Zobraziť viac
            </a>
          </Link>
        </li>
      ) : null}
    </ul>
  );
};

const MenuLevel2Item = ({ category }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <h3>
        <MobileLink
          category={category}
          isOpen={isOpen}
          handleClick={() => setIsOpen(!isOpen)}
        />
        <DesktopLink category={category} />
      </h3>
      {!isEmpty(category?.children) ? (
        <MenuLevel3 parent={category} isOpen={isOpen} />
      ) : null}
    </>
  );
};

const MenuLevel2 = ({ parent, isOpen }) => {
  const { children: categories } = parent;
  if (!categories) {
    return null;
  }

  const categoriesLength = categories.length;
  const modulo4 = categoriesLength % 4;
  const emptyDivsCountToAdd = modulo4 > 0 ? 4 - modulo4 : 0;

  let colWidth = "25%";
  if (categoriesLength < 4) {
    colWidth = `${Math.floor(100 / categoriesLength)}%`;
  }

  return (
    <div className={`${styles?.submenu} ${isOpen ? styles?.active : ""}`}>
      <div className={styles?.submenuColsWrapper}>
        {map(categories, (category) => (
          <div
            className={styles?.submenuCol}
            style={{ width: colWidth }}
            key={`category-${category.id}`}
          >
            {category?.alternativePhoto ? (
              <div className={styles.categoryImage}>
                <Image
                  photo={category?.alternativePhoto}
                  size="120x120"
                  type={"png"}
                  useNextImage={false}
                />
              </div>
            ) : null}
            <MenuLevel2Item category={category} />
          </div>
        ))}
        {emptyDivsCountToAdd > 0 ? (
          <>
            {map(fill(Array(emptyDivsCountToAdd), ""), (item, index) => (
              <div
                className={`${styles?.submenuCol} ${styles?.virtual}`}
                style={{ width: colWidth }}
                key={`category-fake-${index}`}
              ></div>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
};

const MenuItem = ({ category }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <li
      className={
        category?.hash === CATEGORY_HIGHLIGHTED_HASH ? styles?.special : ""
      }
    >
      <MobileLink
        category={category}
        isOpen={isOpen}
        handleClick={() => setIsOpen(!isOpen)}
      />
      <DesktopLink category={category} />

      {!isEmpty(category?.children) ? (
        <MenuLevel2 parent={category} isOpen={isOpen} />
      ) : null}
    </li>
  );
};

const Megamenu = ({ isOpen = false }) => {
  const { data } = useSWR(
    "/api/lib/v1/categories",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const productsCategory = find(data, { hash: PRODUCTS_CATEGORY_HASH });
  if (isEmpty(productsCategory?.children)) {
    return null;
  }

  return (
    <ul className={`${styles?.megamenu} ${isOpen ? styles?.open : ""}`}>
      {map(productsCategory?.children, (category) => (
        <MenuItem category={category} key={`category-${category?.id}`} />
      ))}
    </ul>
  );
};

export default Megamenu;
