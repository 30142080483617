import ecommerce from "grandus-lib/utils/ecommerce";

import { get, set } from "lodash";

const EnhancedEcommerce = {
  impressions: (
    products,
    list = undefined,
    options = { page: 1, perPage: 1 }
  ) => {
    const data = ecommerce.impressions(products, list, options);
    return prepareData(data, "eec.impressionView");
  },
  detail: (product) => {
    const data = ecommerce.detail(product);
    return prepareData(data, 'eec.detail');
  },
  productClick: (
    product,
    list = undefined,
    options = { page: 1, perPage: 1 }
  ) => {
    const data = ecommerce.productClick(product, list, options);
    return prepareData(data, "eec.impressionClick");
  },
  cartAdd: (product, quantity = 1) => {
    const data = ecommerce.cartAdd(product, quantity);
    return prepareData(data, 'eec.add');
  },
  cartRemove: (product, quantity = 1) => {
    const data = ecommerce.cartRemove(product, quantity);
    return prepareData(data, 'eec.remove');
  },
  checkout: (cart, step = null) => {
    const data = ecommerce.checkout(get(cart, 'items', []), step);
    return prepareData(data, "eec.checkout");
  },
  purchase: (order) => {
    const data = ecommerce.purchase(order);
    return prepareData(data, "eec.purchase");
  },
};

const prepareData = (data = {}, event = null) => {
  const ecommerceJson = data;
  if (event) {
    set(ecommerceJson, "event", event);
  }

  return ecommerceJson;
};

export default EnhancedEcommerce;