import useSWR from "swr";
import get from "lodash/get";
import isNull from "lodash/isNull";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

const INFOBOX_VISIBLE = "infobox-visible";

const InfoBox = () => {
  const [isVisible, setVisible] = useState(true);
  const { data, error } = useSWR(
    "/api/lib/v1/banners?type=10",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    const isVisibleSessionValue = JSON.parse(
      sessionStorage.getItem(INFOBOX_VISIBLE)
    );
    if (!isNull(isVisibleSessionValue)) {
      setVisible(isVisibleSessionValue);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem(INFOBOX_VISIBLE, isVisible);
  }, [isVisible]);

  const handleCloseClick = (e) => {
    e.preventDefault();
    setVisible(false);
  };

  const content = get(data, "[0].text");
  if (!isVisible || !content) {
    return null;
  }

  return (
    <div className={"infoBox"}>
      <div className="container">
        <a href="#" className="infoBox__close" onClick={handleCloseClick}>
          <PlusOutlined />
        </a>
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    </div>
  );
};

export default InfoBox;
