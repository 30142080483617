import { Checkbox, message } from "antd";

import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import includes from "lodash/includes";

import useShoppingList from "grandus-lib/hooks/useShoppingList";

import { PlusOutlined } from "@ant-design/icons";

import styles from "components/shoppingList/addButton/Dropdown.module.scss";
import useWishlist from "grandus-lib/hooks/useWishlist";

const AddToWishlistItem = ({ productId = null }) => {
  const { isLoading, itemAdd, itemRemove, itemExists } = useWishlist();

  const text = isLoading
    ? "pracujem..."
    : !itemExists(productId)
    ? "Pridať medzi obľúbené produkty"
    : "Odstrániť z obľúbených produktov";
  return (
    <li
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isLoading) {
          if (itemExists(productId)) {
            message.loading({
              content: "Odoberám produkt z obľúbených produktov",
            });
            itemRemove(productId, () => {
              message.success({
                content: "Produkt bol odstránený z obľúbených produktov",
              });
            });
          } else {
            message.loading({
              content: "Pridávam produkt medzi obľúbené produkty",
            });
            itemAdd(productId, (result) => {
              if (get(result, "errorMessage")) {
                message.error({
                  content: get(result, "errorMessage"),
                });
              } else {
                message.success({
                  content: "Produkt bol pridaný medzi obľúbené produkty",
                });
              }
            });
          }
        }
      }}
    >
      <Checkbox checked={itemExists(productId)}>Obľúbené produkty</Checkbox>
    </li>
  );
};

const ListWithCheckboxes = ({
  productId = null,
  activeLists,
  blacklistedListsIds,
  onAddClick,
  onListItemClick,
  onCloseClick,
}) => {
  const { shoppingLists } = useShoppingList();

  const activeListsIds = map(activeLists, (list) => list?.id);
  const lists = filter(
    shoppingLists,
    (list) => !includes(blacklistedListsIds, list?.id)
  );

  return (
    <div className={styles?.checkboxList}>
      <PlusOutlined
        className={`${styles?.closeButton} close-icon`}
        onClick={onCloseClick}
      />
      <ul>
        {productId ? <AddToWishlistItem productId={productId} /> : null}

        {map(lists, (list) => (
          <li key={`shopping-list-${list?.id}`} onClick={onListItemClick(list)}>
            <Checkbox checked={activeListsIds.includes(list?.id)}>
              {list?.name}
            </Checkbox>
          </li>
        ))}
        <li className={styles?.add} onClick={onAddClick}>
          <span>+</span> Nový zoznam
        </li>
      </ul>
    </div>
  );
};

export default ListWithCheckboxes;
