import { memo, useCallback, useEffect, useRef, useState } from "react";

import find from "lodash/find";

import useShoppingList from "grandus-lib/hooks/useShoppingList";
import useUser from "grandus-lib/hooks/useUser";

import { Button, message } from "antd";
import Dropdown from "./Dropdown";

import styles from "components/shoppingList/addButton/AddButton.module.scss";
import { DownOutlined, UpOutlined, HeartOutlined } from "@ant-design/icons";

const AddButton = ({
  text = "Pridať do zoznamu",
  product,
  store,
  count,
  handleListItemClick,
  selectedProducts,
  className = "",
  list = null,
  disabled = false,
  buttonLoading = false,
  dropdownArrowVisible = true,
  size = null,
  dropdownPosition = 'right'
}) => {
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const { getProductLists, itemAdd, itemRemove } = useShoppingList();

  const wrapperRef = useRef(null);
  const activeLists = getProductLists(selectedProducts || +product?.id);

  const buttonSizeClassName = styles[size] || '';

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDropdownVisible(!isDropdownVisible);
  };

  const closeDropdown = useCallback((e) => {
    e.stopPropagation();
    setIsDropdownVisible(false)
  }, []);

  const handleClickOutside = useCallback(
    (e) => {
      if (
        isDropdownVisible &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target)
      ) {
        setIsDropdownVisible(false);
      }
    },
    [isDropdownVisible, wrapperRef]
  );

  const onListItemClick = (selectedList) => async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    const isActive = find(activeLists, { id: selectedList?.id });

    if (!isActive) {
      message.loading({
        content: "Pridávam produkt do nákupného zoznamu",
      });
      await itemAdd(
        selectedList?.accessToken,
        product?.id,
        count,
        store,
        () => {
          message.success({
            content: "Produkt bol pridaný do nákupného zoznamu",
          });
        }
      );
    } else {
      message.loading({
        content: "Odoberám produkt z nákupného zoznamu",
      });
      const item = find(
        selectedList?.items,
        (item) => item?.product?.id === product?.id
      );
      await itemRemove(selectedList?.accessToken, item?.id, () => {
        message.success({
          content: "Produkt bol odstránený z nákupného zoznamu",
        });
      });
    }
    setIsLoading(false);
  };

  if (!user) {
    return null;
  }

  return (
    <div className={`${styles?.addButton} ${className}`} ref={wrapperRef}>
      <Button
        className={`${styles?.btn} ${buttonSizeClassName}`}
        onClick={handleButtonClick}
        disabled={disabled}
        loading={buttonLoading}
        type="text"
        icon={<HeartOutlined />}
      >
        {text}
        {dropdownArrowVisible ? (
          <span className={styles?.dropdownIcon}>{isDropdownVisible ? <UpOutlined /> : <DownOutlined />}</span>
        ) : null}
      </Button>
      <Dropdown
        isLoading={isLoading}
        visible={isDropdownVisible}
        activeLists={activeLists}
        blacklistedListsIds={[list?.id]}
        onListItemClick={handleListItemClick || onListItemClick}
        onCloseClick={closeDropdown}
        productId={product?.id}
        position={dropdownPosition}
      />
    </div>
  );
};

export default memo(AddButton);
