import useCart from "grandus-lib/hooks/useCart";
import { Table, Typography } from "antd";
import { map, get, isEmpty, find } from "lodash";

import Link from "next/link";

import styles from "./CartSummary.module.scss";
import dynamic from "next/dynamic";
const Image = dynamic(() =>
  import("grandus-lib/components-atomic/image/Image")
);
const Price = dynamic(() =>
  import("grandus-lib/components-atomic/price/Price")
);

const { Paragraph } = Typography;

const ItemCount = ({ item }) => {
  if (get(item, "count") === false) {
    return "";
  }

  const storeId = item?.store?.id;
  const productStore = find(get(item, "product.store"), { id: storeId });

  return (
    <>
      {get(productStore, "piecesInBundle", 1)}&nbsp;
      {!isEmpty(productStore?.alternativeNames)
        ? map(
            productStore?.alternativeNames,
            (obj) => `${obj.name}-${obj.value}`
          )
        : productStore?.name}{" "}
      &times; <span>{get(item, "count", 1)}</span>
    </>
  );
};

const SidebarSummary = ({ children }) => {
  const { cart, isLoading } = useCart();

  if (!isLoading && (isEmpty(cart) || !cart?.count)) {
    return (
      <div style={{ textAlign: "center" }}>
        <img
          src="https://www.freshbox.sk/files/attachment/2020-10/91210/74f64a/avocado.svg"
          width="150px"
        />
        <h3>Váš nákupný košík je prázdny</h3>
      </div>
    );
  }

  const tableColumns = [
    {
      title: "",
      responsive: ["sm"],
      render: (item) => {
        if (!get(item, "product.photo")) {
          return "";
        }
        return (
          <Link
            href="/produkt/[id]"
            as={`/produkt/${get(item, "product.urlTitle")}`}
          >
            <a>
              {get(item, "product.photo") ? (
                <Image
                  photo={get(item, "product.photo")}
                  size={"50x50"}
                  type={"jpg"}
                />
              ) : (
                ""
              )}
            </a>
          </Link>
        );
      },
    },
    {
      title: "Položka",
      render: (item) => {
        return (
          <>
            <Paragraph ellipsis={{ rows: 3 }}>
              {get(item, "product.urlTitle") ? (
                <Link
                  href="/produkt/[id]"
                  as={`/produkt/${get(item, "product.urlTitle")}`}
                >
                  <a>{get(item, "product.name")}</a>
                </Link>
              ) : (
                get(item, "product.name")
              )}
            </Paragraph>
            <ItemCount item={item} />
          </>
        );
      },
    },
    {
      title: "Cena",
      align: "right",
      render: (item) => (
        <>
          <b style={{ whiteSpace: "nowrap" }}>
            <Price priceData={get(item, "priceTotalData")} />
          </b>
        </>
      ),
    },
  ];

  return (
    <div className={styles.sidebarSummary}>
      <Table
        loading={isLoading}
        showHeader={false}
        pagination={false}
        columns={tableColumns}
        rowKey={(record) => record.id}
        dataSource={get(cart, "items", [])}
        size={"small"}
      />
      {children}
    </div>
  );
};

export default SidebarSummary;
