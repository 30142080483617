import Link from "next/link";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import toNumber from "lodash/toNumber";
import first from "lodash/first";
import EnhancedEcommerce from "utils/ecommerce";
import TagManager from "grandus-lib/utils/gtag";
import Image from "grandus-lib/components-atomic/image/Image";
import Price from "grandus-lib/components-atomic/price/Price";
import AddToCartButton from "components/cart/AddToCartButton";
import ProductLabels from "components/product/card/ProductLabels";
import { RightOutlined } from "@ant-design/icons";

import WishlistButton from "components/wishlistButton/WishlistButton";
import AddToShoppingListButton from "components/shoppingList/addButton/AddButton";

import styles from "components/product/card/ProductCard.module.scss";
import { useRouter } from "next/router";
import { addPopuptoUrl } from "components/product/modal";

const ProductPrice = ({ product, isBox }) => {
  if (isEmpty(product)) {
    return null;
  }

  const { finalPriceData, standardPriceData, discount, subtitle } = product;
  return (
    <>
      <Price
        priceData={finalPriceData}
        className={`${styles.price} ${discount ? styles.discount : ""} ${
          !subtitle ? styles.withGutter : null
        } ${isBox ? styles.boxPrice : ""}`}
        options={{
          mainPriceClass: styles.mainPrice,
          withoutVatPriceClass: styles.withoutVat,
          hideVatPrice: true,
        }}
      />
      {toNumber(get(standardPriceData, "price")) >
      toNumber(get(finalPriceData, "price")) ? (
        <span className={styles?.strikethroughPrice}>
          {get(standardPriceData, "priceFormatted")}
        </span>
      ) : (
        <span>&nbsp;</span>
      )}
    </>
  );
};

const ProductCard = ({ options = {}, ...product }) => {
  const {
    id,
    name,
    subtitle,
    kind,
    urlTitle,
    shortDescription,
    finalPriceData,
    standardPriceData,
    photo,
    store,
    discount,
    isOrderable,
    storeStatus,
  } = product;
  const router = useRouter();
  const onClickHandler = (e) => {
    e.preventDefault();

    TagManager.push(
      EnhancedEcommerce.productClick(
        { ...product, ...{ position: get(options, "eecProduct.position") } },
        "category",
        {
          page: get(options, "eecProduct.page"),
          perPage: get(options, "eecProduct.perPage"),
        }
      ),
      () => {
        router.replace(
          addPopuptoUrl(window?.location?.href, urlTitle),
          undefined,
          {
            shallow: true,
            scroll: false,
          }
        );
      }
    );
  };

  const isAvailable = isOrderable;

  let isBox = get(kind, "name") == "Box" ? true : false;
  return (
    <div className={styles.productCardWrapper}>
      <div
        className={`${styles.productCard} ${
          isAvailable ? "" : styles.nonAvailable
        }`}
      >
        <div className={styles.imageWrapper}>
          <Link href="/produkt/[id]" as={`/produkt/${urlTitle}`} scroll={false}>
            <a onClick={onClickHandler}>
              {isBox ? (
                <Image
                  photo={photo}
                  size={"390x260"}
                  type={"jpg"}
                  className={styles.image}
                />
              ) : (
                <Image
                  photo={photo}
                  size={"285x190"}
                  type={"jpg"}
                  usePlacehoder
                  className={styles.image}
                />
              )}
            </a>
          </Link>
          {isAvailable ? <ProductLabels {...product} /> : ""}
          {isBox ? null : (
            <div className={styles?.wishlistButton}>
              {/* <WishlistButton
                    productId={product?.id}
                    options={{ hideText: true }}
                  /> */}
              <AddToShoppingListButton
                product={product}
                count={1}
                store={first(store)?.id}
                text=""
                dropdownArrowVisible={false}
                size="large"
                dropdownPosition="left"
              />
            </div>
          )}
        </div>
        <div className={styles.content}>
          <Link href="/produkt/[id]" as={`/produkt/${urlTitle}`} scroll={false}>
            <a onClick={onClickHandler}>
              <div className={styles.title}>{name}</div>
            </a>
          </Link>
          {isBox ? (
            <div className={styles?.wishlistButton}>
              {/* <WishlistButton
                      productId={product?.id}
                      options={{ hideText: true }}
                    /> */}
              <AddToShoppingListButton
                product={product}
                count={1}
                store={first(store)?.id}
                text={""}
                dropdownArrowVisible={false}
                size="large"
                dropdownPosition="left"
              />
            </div>
          ) : null}
          {options?.hideDescription ? null : (
            <>
              {isAvailable ? (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: shortDescription,
                  }}
                />
              ) : (
                <div className={styles.description}>
                  <strong className={styles.alertText}>
                    Tovar nie je aktuálne dostupný.
                  </strong>
                  <br />
                  {get(
                    storeStatus,
                    "messageUnavailable",
                    "Pracujeme na jeho naskladnení."
                  )}
                </div>
              )}
            </>
          )}
          <ProductPrice product={product} isBox={isBox} />
          <span className={styles.subtitle}>{subtitle}</span>
        </div>
        <div className={styles.content}>
          <div
            className={`${styles.buttons} ${isBox ? styles.alignRight : ""}`}
          >
            {isAvailable ? (
              <>
                <AddToCartButton
                  stores={store}
                  productId={id}
                  options={{ useToastr: true }}
                />
                {isBox ? null : (
                  <div className={styles.fallback}>
                    <Link href="/produkt/[id]" as={`/produkt/${urlTitle}`}>
                      <a>
                        Detail <RightOutlined />
                      </a>
                    </Link>
                  </div>
                )}
              </>
            ) : (
              <div className={styles.availabilityMessage}>
                <strong className={styles.alertText}>
                  Tovar nie je aktuálne dostupný.
                </strong>
                {get(
                  storeStatus,
                  "messageUnavailable",
                  "Pracujeme na jeho naskladnení."
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
