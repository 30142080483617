import { get, groupBy, map, slice, find } from "lodash";
import styles from "./index.module.scss";

import { ADD_INFO_TITLE_HASH, BOX_HASH_VIDEO } from "constants/ProductConstants";

import dynamic from "next/dynamic";
import useWishlist from "grandus-lib/hooks/useWishlist";
const CrossSale = dynamic(() => import("components/product/detail/crossSale"), {
  ssr: false,
});
const Related = dynamic(
  () => import("components/product/detail/relatedProducts"),
  {
    ssr: false,
  }
);

//PARTIALS
import PartialAddToCart from "components/product/detail/_partials/PartialAddToCart";

import Image from "grandus-lib/components-atomic/image/Image";
import Gallery from "components/gallery/GallerySlider";

const WishlistButton = ({ productId }) => {
  const { isLoading, itemAdd, itemRemove, itemExists } = useWishlist();

  const text = isLoading
    ? "pracujem"
    : !itemExists(productId)
    ? "pridat do oblubenych"
    : "odstranit z oblubenych";

  return (
    <span
      onClick={(e) =>
        !isLoading
          ? itemExists(productId)
            ? itemRemove(productId)
            : itemAdd(productId)
          : null
      }
    >
      {text}
    </span>
  );
};

const Box = ({ product }) => {
  const storeStatus = get(product, "storeStatus");
  const groupedParameters = groupBy(
    get(product, "detailedParameters", []),
    (parameter) => parameter.group
  );
  const ingredientsPhoto = get(product, "gallery[0]");
  const boxVideo = find(
    get(product, "additionalInfos", []),
    (item) => item.hash == BOX_HASH_VIDEO
  );

  const addInfoTitleParam = find(product?.detailedParameters, {
    hash: ADD_INFO_TITLE_HASH,
  });

  return (
    <>
      <div
        id={get(styles, "product-detail")}
        className={product.isOrderable ? "" : styles.nonAvailable}
      >
        <div className={styles["container"]}>
          <div className={styles["row"]}>
            <div className={styles["col"] + " " + styles["col-5"]}>
              <h1>{product?.name}</h1>
              <div className={styles["subtitle"]}>{product?.subtitle}</div>

              <div className={styles["top-parameters"]}>
                {map(
                  slice(get(product, "detailedParameters", []), 0, 3),
                  (parameter) => {
                    const parameterPhoto = {
                      path: get(parameter, "photoPath"),
                    };
                    return (
                      <div
                        className={styles["parameter"]}
                        key={"parameter-key-id-" + parameter.id}
                      >
                        {get(parameter, "photoPath") ? (
                          <Image
                            photo={parameterPhoto}
                            size={"58x58"}
                            type={"png"}
                          />
                        ) : null}
                        <div className="parameter-name">{parameter.name}</div>
                        <div className="parameter-value">{parameter.value}</div>
                      </div>
                    );
                  }
                )}
              </div>

              <div
                className={get(styles, "short-description")}
                dangerouslySetInnerHTML={{
                  __html: get(product, "shortProductDescription.description"),
                }}
              />

              {/* <div className={styles["prices"]}>
                <div className={styles["selling-price"]}>
                  {get(product, "finalPriceData.priceFormatted")}
                </div>
                {product?.discount ? (
                  <div className={styles["origin-price"]}>
                    {get(product, "priceData.priceFormatted")}
                  </div>
                ) : (
                  ""
                )}
              </div> */}

              {/* <div className={styles["availability"]}>
                {storeStatus?.available ? (
                  <span
                    className={`${styles.availability} ${styles.available}`}
                  >
                    {get(storeStatus, "message")}
                  </span>
                ) : (
                  <span
                    className={`${styles.availability} ${
                      get(storeStatus, "deliveryDate.date")
                        ? styles.arriving
                        : styles.unavailable
                    }`}
                  >
                    {get(storeStatus, "deliveryDate.date") ? "Očakávame" : ""}{" "}
                    {get(storeStatus, "message")}
                  </span>
                )}
              </div> */}

              <PartialAddToCart product={product} styles={styles} />
            </div>
            <div className={styles["col"] + " " + styles["col-1"]}>&nbsp;</div>
            <div className={styles["col"] + " " + styles["col-6"]}>
              <div className={styles["gallery-wrapper"]}>
                <Gallery
                  titleImage={get(product, "photo", null)}
                  images={get(product, "gallery", [])}
                  options={{
                    imageMainProps: {
                      size: "580x375__cropped",
                      className: product.isOrderable ? "" : "nonAvailable",
                    },
                    imageGalleryProps: {
                      size: "580x375__cropped",
                      className: product.isOrderable ? "" : "nonAvailable",
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <div className={styles["row"] + " " + styles["ingredients-section"]}>
            {ingredientsPhoto ? (
              <>
                <div className={styles["col"] + " " + styles["col-8"]}>
                  <Image
                    className={styles["ingredients-photo"]}
                    photo={ingredientsPhoto}
                    size={"780x410__cropped"}
                    type={"jpg"}
                  />
                </div>
                <div className={styles["col"] + " " + styles["col-4"]}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: get(product, "productDescription.description"),
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles["col"]}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: get(product, "productDescription.description"),
                  }}
                />
              </div>
            )}
          </div>

          {get(product, "relatedProducts", []).length ? (
            <div className={styles.container}>
              <div className={styles.col}>
                <h3>Mohlo by Vás zaujímať</h3>
                <Related data={get(product, "relatedProducts", [])} />
              </div>
            </div>
          ) : (
            ""
          )}

          {boxVideo || get(product, "additionalInfos", []).length ? (
            <div className={styles["recipe-process"]}>
              <h2>{get(addInfoTitleParam, "value") || "Postup varenia"}</h2>
              <div className={styles["container"]}>
                {boxVideo ? (
                  <div className={styles["row"]}>
                    <div className={styles["col"] + " " + styles["col-12"]}>
                      <div className={styles["recipe-video"]}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: get(boxVideo, "text", ""),
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className={styles["row"]}>
                  {map(get(product, "additionalInfos", []), (record) => {
                    if (get(record, "hash", "") == BOX_HASH_VIDEO) {
                      return null;
                    }
                    return (
                      <div
                        className={styles["col"] + " " + styles["col-6"]}
                        key={"product-additional-" + record.id}
                      >
                        <div className={styles["recipe-step"]}>
                          {get(record, "photo") && (
                            <Image
                              photo={get(record, "photo")}
                              size={"600x340__cropped"}
                              type={"jpg"}
                            />
                          )}
                          <div className={styles["recipe-step-content"]}>
                            <h3>{get(record, "title", "")}</h3>
                            <div
                              className={styles["text"]}
                              dangerouslySetInnerHTML={{
                                __html: get(record, "text", ""),
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.col}>
          <CrossSale data={get(product, "crosssaleProducts", [])} />
        </div>
      </div>
    </>
  );
};

export default Box;
