import { get, toLower, map, toNumber, isEmpty, groupBy } from "lodash";
import Image from "grandus-lib/components-atomic/image/Image";
import styles from "components/product/card/ProductLabels.module.scss";
const ProductLabels = ({
  urlTitle,
  status,
  new: newattr,
  favourite,
  labels,
  discount,
  productHooks,
  inline = false,
  options = {},
}) => {
  let statusBackground = get(options, "background", "orange");
  const showGift = !isEmpty(get(productHooks, "hard", null));
  const grouppedLabels = groupBy(
    isEmpty(labels) ? [] : labels,
    (label) => label.group
  );
  return (
    <div className={`${styles.labels} ${inline ? styles.inline : ""}`}>
      {favourite ? (
        <div className={`${styles.label} ${styles.favourite}`}>Obľúbené</div>
      ) : (
        ""
      )}
      {status ? (
        <div
          className={`${styles.label} ${styles.status}`}
          style={{ backgroundColor: statusBackground }}
        >
          {status}
        </div>
      ) : (
        ""
      )}
      {discount ? (
        <div className={`${styles.label} ${styles.discount}`}>Zľava</div>
      ) : (
        ""
      )}
      {newattr ? (
        <div className={`${styles.label} ${styles.new}`}>Novinka</div>
      ) : (
        ""
      )}
      {showGift ? (
        <div className={`${styles.label} ${styles.gift}`}>Darček</div>
      ) : (
        ""
      )}
      {labels
        ? map(grouppedLabels, (labelGroup, labelGroupName) => {
            if (labelGroupName) {
              return (
                <div
                  className={`${styles.label}`}
                  style={{
                    backgroundColor: get(labelGroup, "[0].backgroundColor"),
                    color: get(labelGroup, "[0].textColor"),
                  }}
                  key={`product-card-labels-custom-${urlTitle}-${labelGroupName}`}
                >
                  {labelGroupName}:
                  {map(labelGroup, (label, index) => (
                    <span
                      style={{ marginLeft: "5px" }}
                      key={`product-card-labels-custom-${urlTitle}-${index}-${labelGroupName}-${get(
                        label,
                        "name"
                      )}`}
                    >
                      {get(label, "name")}
                    </span>
                  ))}
                </div>
              );
            } else {
              return map(labelGroup, (label, index) => {
                if(get(label, 'photo')){
                    return (
                        <Image 
                            photo={get(label, 'photo')} 
                            size={"60__cropped"} 
                            type={"png"} 
                            key={`product-card-labels-custom-${urlTitle}-${index}-${get(
                                label,
                                "name"
                              )}`}
                        />
                    )
                }
                return (
                  <div
                    className={`${styles.label}`}
                    style={{
                      backgroundColor: get(label, "backgroundColor"),
                      color: get(label, "textColor"),
                    }}
                    key={`product-card-labels-custom-${urlTitle}-${index}-${get(
                      label,
                      "name"
                    )}`}
                  >
                    {get(label, "name")}
                  </div>
                );
              });
            }
          })
        : ""}
    </div>
  );
};
export default ProductLabels;